footer {
    padding-left: 20%;
    padding-right: 20%;
    background-color: #141E46;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

footer p,
footer .link {
    color: white;
    text-decoration: none;
    font-weight: 00;
    margin-left: 2%;
    transition: all .25s ease-in-out;
}
footer .link:hover {
    color: #ffd292;
}

footer h2,
footer address {
    color: white;
    font-style: normal;
    margin-left: 2%;
}

footer .section-row {
    display: flex;
    align-items: center;
}

footer .working-hours {
    display: flex;
    justify-content: space-between;
}

footer hr {
    height: 1px;
    width: auto;
    background-color: white;
    margin-right: 0px;
}
footer .credit {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 10px;
}
footer .credit p{
    color: white;
    font-size: 12px;
    margin-left: 5px;
}

@media (max-width: 768px) {

    /* Mobil cihazlar için stil tanımları */
    footer {
        padding-left: 10%;
        padding-right: 10%;
        align-items: center;
    }

    footer h2 {
        text-align: center;
    }

    .quick-links {
        display: none;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {

    /* Tabletler için stil tanımları */
    footer {
        padding-left: 25%;
        padding-right: 25%;
        align-items: center;
    }

    footer h2 {
        text-align: center;
    }

    .quick-links {
        display: none;
    }
}