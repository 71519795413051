.main-about {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 10%;
    padding-bottom: 10%;
}

.main-about .image-container {
    position: relative;
    top: 0;
    left: 0;
}

.main-about .image1 {
    position: relative;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 95%;
    height: 45vh;
}

.main-about .image2 {
    position: absolute;
    top: -50px;
    left: -50px;
    border: 8px solid white;
    width: 30%;
    height: auto;
}

.main-about h1 {
    color: hsl(233, 80%, 10%);
    font-size: 40px;
}

.main-about p {
    color: #757575;
    font-size: 18px;
}

.teachers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.teachers h3 {
    color: #F3BD00;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .main-about {
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 40%;
        padding-bottom: 15%;
        text-align: center;
    }

    .main-about .image1 {
        position: relative;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 40vh;
    }

    .main-about .image2 {
        position: absolute;
        top: -50px;
        left: -30px;
        border: 10px solid white;
        width: 40%;
        height: auto;
    }

    .main-about h1 {
        font-size: 25px;
    }

    .main-about p {
        font-size: 16px;
    }

    .main-about .teachers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}