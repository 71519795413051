.container {
    display: flex;
    padding-bottom: 2%;
    flex-direction: column;
    align-items: center;
    position: relative; /* Gerekli */
    text-align: center;
}

.container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/header.jpg');
    background-position: 30%;
    filter: brightness(60%);
    z-index: -1;
}

.page-name {
    color: white;
    font-size: 2.8em;
}
@media (max-width: 768px) {
    .container {
        padding-bottom: 10%;
    }
    .page-name {
        color: white;
        font-size: 2em;
    }
  }