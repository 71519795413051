.main-foreigners {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 10%;
  padding-bottom: 10%;
}

.image-container {
  position: relative;
  top: 0;
  left: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 80%;
  height: 65vh;
}

.image2 {
  position: absolute;
  top: -50px;
  left: -50px;
  border: 15px solid white;
  width: 30%;
  height: auto;
}

.main-foreigners h1 {
  color: hsl(233, 80%, 10%);
  font-size: 40px;
}

.main-foreigners p {
  color: #757575;
  font-size: 18px;
}

.main-foreigners ul {
  display: flex;
  flex-direction: column;
  color: #757575;
  margin-top: 1%;
  margin-bottom: 5%;
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 10px;
}

.main-foreigners ul li {
  font-size: 18px;
}

.main-foreigners ul li:not(:last-child) {
  margin-bottom: 1%;
}

@media (max-width: 768px) {
  .main-foreigners {
    padding-left: 15%;
    padding-right: 5%;
    padding-top: 40%;
    padding-bottom: 20%;
  }

  .image1 {
    position: relative;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 30vh;
  }

  .image2 {
    position: absolute;
    top: -50px;
    left: -30px;
    border: 10px solid white;
    width: 40%;
    height: auto;
  }

  .main-foreigners h1 {
    font-size: 25px;
  }

  .main-foreigners p {
    font-size: 16px;
  }

  .main-foreigners ul li {
    font-size: 16px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .image1 {
    position: relative;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 40vh;
  }

  .image2 {
    position: absolute;
    top: -50px;
    left: -30px;
    border: 10px solid white;
    width: 40%;
    height: auto;
  }
}