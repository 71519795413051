.main-gallery {
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 10%;
    padding-bottom: 10%;
}

@media (max-width: 768px) {
    .main-gallery {
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 10%;
        padding-top: 10%;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .main-gallery {
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 10%;
        padding-top: 10%;
    }
  }
  