.main-classes {
    display: flex;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 10%;
    padding-bottom: 10%;
}

@media (max-width: 768px) {
    .main-classes {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 30%;
        padding-bottom: 30%;
    }
}