.main-home {
    padding: 0;
    margin-right: 0;
}

.carousel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70vh;
    position: relative;
    background-image: url('../../assets//carousel-background.jpg');
    color: rgb(12, 0, 94);
}

.carousel-background-image {
    width: 100%;
    object-fit: cover;
}

.carousel-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.carousel-left-content {
    position: absolute;
    left: 15%;
    width: 40%;
}


@keyframes h2-animation {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.carousel-content h2 {
    font-size: 18px;
    animation-name: h2-animation;
    animation-duration: 0.8s;
}

@keyframes h1-animation {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.carousel-content h1 {
    animation-name: h1-animation;
    animation-duration: 1s;
}

@keyframes p-animation {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.carousel-content p {
    width: 80%;
    animation-name: p-animation;
    animation-duration: 1.2s;
}

@keyframes img-animation {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.carousel-content img {
    position: absolute;
    right: 15%;
    width: 30%;
    animation-name: img-animation;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;

}

.contact-button {
    margin-top: 20px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #E31E23;
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    width: fit-content;
    animation-name: p-animation;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    transition: all .5s ease;
}

.contact-button:hover {
    background-color: #ff0008;
}

.carousel-button {
    height: max-content;
    position: absolute;
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #969696;

    transition: all .5s ease;
}

.carousel-button:hover {
    color: #333;
}

.carousel-button.prev {
    left: 10px;
}

.carousel-button.next {
    right: 10px;
}

.carousel-dots {
    display: flex;
    position: absolute;
    bottom: 5%;
}

.carousel-dot {
    margin: 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.carousel-dot.active {
    background-color: #333;
}

.about-section {
    margin-top: 10%;
    padding-left: 20%;
    padding-right: 20%;
}

.about-section .image-container {
    position: relative;
    top: 0;
    left: 0;
}

.about-section .image1 {
    width: 95%;
    height: 45vh;
    position: relative;
    top: 0;
    left: 0;
    object-fit: cover;
}

.about-section .image2 {
    position: absolute;
    top: -50px;
    left: -50px;
    border: 10px solid white;
    width: 30%;
    height: auto;
}

.about-section p {
    margin-bottom: 5%;
    color: #757575;
}

.about-section .button {
    width: 17vh;
    margin-top: 20px;
    padding: 10px;
    text-decoration: none;
    border-radius: 5px;
    border: none;
    background-color: #E31E23;
    color: white;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    transition: all .5s ease;

}
.about-section .button:hover {
    background-color: #ff0008;
}

.info-boxes-section {
    padding-bottom: 10%;
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-box {
    width: 90%;
    height: 25vh;
    padding: 5%;
    text-align: center;
    background-color: #F1F3F2;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1024px) {
    .carousel {
        height: 90vh;
    }
    .carousel-content {
        flex-direction: column;
    }

    .carousel-left-content {
        position: static;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
    }

    .carousel-content h2 {
        font-size: 16px;
    }

    .carousel-content h1 {
        font-size: 20px;
        margin-left: 5%;
        margin-right: 5%;
    }

    .carousel-content p {
        font-size: 14px;
    }

    .carousel-content img {
        position: static;
        width: 60%;
    }
}

@media (max-width: 768px) {

    .about-section {
        padding-left: 10%;
        padding-right: 5%;
        padding-top: 30%;
    }

    .about-section .image1 {
        position: relative;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 40vh;
    }

    .about-section .image2 {
        position: absolute;
        top: -50px;
        left: -30px;
        border: 10px solid white;
        width: 40%;
        height: auto;
    }

    .about-section p {
        margin-bottom: 10%;

    }

    .about-section .teachers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .info-boxes-section {
        padding-bottom: 30%;
        padding-left: 5%;
        padding-right: 5%;
    }

    .info-box {
        height: auto;
    }
}

@media (min-width: 600px) and (max-width: 1024px) {
    .carousel-section {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .carousel-section .button {
        position: static;
    }
}