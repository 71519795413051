.main-contact {
  padding: 8%;
}

.google-maps {
  position: relative;
  padding-bottom: 75%;
}

.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 90% !important;
  height: 80% !important;
  border-radius: 30px;
}

.main-contact p,
address {
  color: black;
  font-style: normal;
}

.section-row {
  align-items: center;
}

@media (max-width: 768px) {
  .main-contact {
    padding-top: 20%;
    padding-bottom: 20%;
    padding-right: 5%;
    padding-left: 5%;
  }

  .google-maps iframe {
    width: 100% !important;
    height: 100% !important;
    border-radius: 10px;
  }
}