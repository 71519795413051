.main-vehicles {
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 10%;
    padding-bottom: 10%;
}

.card {
    max-width: 400px;
}

@media (max-width: 921px) {
    .main-vehicles {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 20%;
        padding-bottom: 20%;
    }

    .card {
        max-width: 100%;
    }
}