.main-documents {
    display: flex;
    flex-direction: column;
    padding: 5%;
    padding-left: 15%;
    padding-right: 15%;
}

.section {
    margin-bottom: 0px;
}

.main-documents h2 {
    color: black;
    margin: 0;

}

.main-documents ul {
    display: flex;
    flex-direction: column;
    color: #757575;
    margin-top: 1%;
    margin-bottom: 5%;
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 10px;
}

.main-documents ul li {
    font-size: 18px;
    font-family: 'Roboto'
}

.main-documents ul li:not(:last-child) {
    margin-bottom: 1%;
}

.main-documents span {
    color: red;
    font-weight: normal;
    font-size: 18px;
    font-family: 'Roboto'
}

table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
    color: #757575;
}

th,
td {
    text-align: left;
    padding: 15px;
    border-bottom: 1px solid #ddd;
}

th:nth-child(2),
td:nth-child(2) {
    text-align: right;
}

@media (max-width: 768px) {
    .main-documents {
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 20%;
        padding-bottom: 30%;
        text-align: center;
    }

    .section {
        margin-bottom: 75px;
    }

    .main-documents h2 {
        text-align: center;
        margin-bottom: 8%;
    }

    .main-documents ul li {
        font-size: 16px;
    }

    .main-documents ul li:not(:last-child) {
        margin-bottom: 25px;
    }

    .main-documents span {
        font-size: 16px;
    }
}