.main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 3%;
    padding-bottom: 2%;
    padding-left: 10%;
    padding-right: 10%;
    position: relative;
}

.active {
    color: #F3BD00;
}

.main-header .left-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.main-header .logo {
    width: 150px;
    margin-right: 20px;
}

.main-header .menu-toggle {
    display: none;
    cursor: pointer;
}

.main-header hr {
    border: none;
    border-left: 1px solid black;
    height: 60px;
    width: 1px;
    margin-right: 20px;
}

.left-group span {

    font-size: 20px;
    font-weight: 500;
}

.main-header ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.main-header li {
    float: left;
}

.main-header li a:hover {
    color: #F3BD00;
}

.main-header li a:active {
    color: #F3BD00;
}

.main-header li a,

.main-header .dropbtn {
    display: inline-block;
    color: black;
    text-align: center;
    padding: 1.5vh;
    text-decoration: none;
    font-family: 'Poppins';
    font-size: 1.1em;
    transition: all 0.25s ease-in-out;
}


.main-header li.dropdown {
    display: inline-block;
}

.main-header .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    animation: growDown 800ms ease-out forwards;
        transform-origin: top center;
}

.main-header .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    font-size: 14px;
}

.main-header .dropdown-content a:hover {
    background-color: #f1f1f1
}

.main-header .dropdown .dropdown-content {
    display: flex;
    flex-direction: column;
}
@keyframes growDown {
    0% {
        opacity: 0;
        transform: rotateX(-90deg);
      }
      50% {
        transform: rotateX(-20deg);
      }
      100% {
        opacity: 1;
        transform: rotateX(0deg);
      }
}
@media (max-width: 768px) {

    /* Mobil cihazlar için stil tanımları */
    .main-header {
        padding-left: 5%;
        padding-right: 5%;
        display: block;
        overflow: hidden;
    }

    .main-header .left-group {
        display: inline-block;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .main-header li a,
    .main-header .dropbtn {
        color: #636c77;
        text-align: center;
        font-size: 1em;
    }
    .main-header .dropdown-content a {
        color: #7c8896;
        font-size: 0.9em;
        padding-left: 30px;
    }
    .main-header .logo {
        width: 120px;
        position: relative;
        display: flex;
    }

    .main-header .menu-toggle {
        display: block;
        float: right;
        position: absolute;
        top: 27px;
        right: 15px;
    }

    .left-group hr {
        display: none;
    }

    .left-group span {
        display: none;
    }

    .main-header ul:not(:first-child),
    .dropdown .dropbtn {
        display: none;
    }

}

@media (min-width: 769px) and (max-width: 1024px) {

    /* Tabletler için stil tanımları */
    .left-group span {
        display: none;
    }

    .left-group hr {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .main-header.responsive {
        position: relative;
        overflow: hidden;
        
    }

    .main-header .responsive .menu-toggle {
        position: absolute;
        right: 0;
        top: 0;
    }

    .main-header.responsive .logo {
        position: relative;
    }
    .main-header.responsive ul {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-top: 20px;
        animation: growDown 800ms ease-out forwards;
        transform-origin: top center;
    }

    .main-header.responsive .dropdown {
        text-align: left;
    }

    .main-header.responsive .dropdown-content {
        position: relative;
        box-shadow: none;
        justify-content: center;
        align-items: left;
        animation: growDown 800ms ease-out forwards;
        transform-origin: top center;
    }

    .main-header.responsive .dropdown .dropbtn {
        display: block;
        text-align: left;
    }

}