.main-simulator {
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 10%;
    padding-bottom: 10%;
}

.simulator-image {
    width: 300px;
    border-radius: 5px;
}

.main-simulator p {
    color: #757575;
}

.main-simulator ul {
    display: flex;
    flex-direction: column;
    color: #757575;
    margin-top: 1%;
    margin-bottom: 5%;
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 10px;
}

.main-simulator ul li {
    font-size: 18px;
}

.main-simulator ul li:not(:last-child) {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .main-simulator {
        padding-left: 15%;
        padding-right: 5%;
        padding-top: 20%;
        padding-bottom: 20%;
    }

    .simulator-image {
        width: 90%;
    }

    .main-simulator h1 {
        font-size: 20px;
        text-align: center;
    }

    .main-simulator p {
        font-size: 16px;
    }

    .main-simulator ul li:not(:last-child) {
        margin-bottom: 1px;
    }
}